/* PRINT */
@media print {
  body {
    -webkit-print-color-adjust: exact;

    &:not(.print_menu) {
      .accordion-count, .accordion-title {
        color: #ffffff !important;
      }

      .content-title {
        background-color: #ffffff !important;

        .breadcrumbs {
          background-color: #ffffff !important;

          ol {
            background-color: #ffffff !important;
          }
        }
      }

      .panel-heading {
        background-color: #ffffff !important;
      }
    }

    a:after {
      content: none !important;
    }

    .collapse {
      display: block !important;
      height: auto !important;
    }

    #wrapper {
      background-image: none !important;
      background-color: white !important;
      margin: 0;
    }

    #page-content-wrapper {
      width: 100% !important;
      margin: 0;

      .container-fluid {
        -webkit-box-shadow: none !important;
        -moz-box-shadow: none !important;
        box-shadow: none !important;
      }

      > div,
      .content-dynamic {
        margin: 0;
      }
    }

    .accordion-count, .accordion-title {
      font-size: 18px !important;
      padding: 7px !important;
      width: auto !important;
      height: auto !important;
    }

    .content-title {

      .widget-headline-icon {
        display: none !important;
      }

      .breadcrumbs {
        width: 100% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;

        ol {
          font-size: 18px !important;
          display: block;

          li {
            display: inline-block;
            font-size: 18px;

            span {
              font-size: 18px !important;

              &:before {
                content: '/';
                font-size: 18px;
                padding-left: 10px;
                padding-right: 10px;
              }
            }
          }
        }
      }
    }

    .footer-masked,
    .hidden-print,
    #sidebar-right,
    #sidebar-left,
    #comment-section,
    input,
    label,
    .dt-buttons.btn-group,
    .dataTables_length,
    .modal,
    .noprint,
    div.alert,
    header,
    .pagination,
    .group-media,
    .btn,
    .footer,
    form,
    .pager,
    #comments,
    .dataTables_info,
    .nav,
    ul.links.list-inline,
    ul.action-links,
      //hide seetings
    .dropdown-toggle.hidden-print + ul.dropdown-menu {
      display: none !important;
    }

    table, figure {
      page-break-inside: avoid;
    }
  }
}

